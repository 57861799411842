.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.vertical-center {
  position: absolute;
  bottom: 0%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
input[type="button"],
input[type="submit"],
button {
  border: none;
  align-self: flex-end;
  font-size: 16px;
  font-weight: 800;
  padding: 7.5px;
  background: rgb(0, 174, 240);
  color: white;
  border-radius: 7.5px;
  cursor: pointer;
}
button:hover {
  background: #25293c;
}

input[type="checkbox"] {
  min-width: 20px;
  min-height: 20px;
  background-color: white;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: #4e4e4e;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  accent-color: blueviolet;
  border-radius: 5px;
}
input[type="checkbox"]:checked {
  background-color: #00b4ec;
  border:  none;
}
label {
  cursor: pointer;
}

input[type="text"],
input[type="password"] {
  border: none;
  outline: none;
  padding: 10px;
  cursor: pointer;
}
::placeholder {
  color: #4e4e4e;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
